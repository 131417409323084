@import url('https://fonts.googleapis.com/css2?family=Calligraffitti&display=swap');

@media (max-width: 599px) {
    div{
        padding: 5px;
    }
    .dispositionImageBoulot{
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .titre{
        color: bisque;
        width: 50%;
        margin-top: 10px;
    }
    .fontImage{
        background-image: url("../../public/font_principal.png");
        background-repeat: no-repeat;
        background-size: cover;
        font-family: 'Calligraffitti', cursive;
    }
    .containerProjets {
        display: flex;
        text-align: center;
        justify-content: center;
        flex-direction: column;
        padding: 10px;
        align-items: center;
        flex-wrap: wrap;

    }
    .card {
       display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;
    }

    .image {
        padding: 0;
        margin: 0;
        background-image: url(../../public/img.png); /*or 70%, or what you want*/ /*or 70%, or what you want*/
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        width: 100%;
        margin: auto;
        height: 50em;
    }
    .card {
        background: rgb(199, 234, 130);
        background: radial-gradient(circle, rgb(162, 230, 141) 0%, rgb(236, 244, 3) 100%);
        line-height: 42px;
        padding: 0;
        border: none;
    }
    .card span {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
    }
    .card:before,
    .card:after {
        position: absolute;
        content: "";
        height: 0%;
        width: 1px;
        box-shadow:
                -1px -1px 20px 0px rgba(255,255,255,1),
                -4px -4px 5px 0px rgba(255,255,255,1),
                7px 7px 20px 0px rgba(0,0,0,.4),
                4px 4px 5px 0px rgba(0,0,0,.3);
    }
    .card:before {
        right: 0;
        top: 0;
        transition: all 500ms ease;
    }
    .card:after {
        left: 0;
        bottom: 0;
        transition: all 500ms ease;
    }

    .card:hover:before {
        transition: all 500ms ease;
        height: 100%;
    }
    .card:hover:after {
        transition: all 500ms ease;
        height: 100%;
    }
    .card span:before,
    .card span:after {
        position: absolute;
        content: "";
        box-shadow:
                -1px -1px 20px 0px rgba(255,255,255,1),
                -4px -4px 5px 0px rgba(255,255,255,1),
                7px 7px 20px 0px rgba(0,0,0,.4),
                4px 4px 5px 0px rgba(0,0,0,.3);
    }
    .card span:before {
        left: 0;
        top: 0;
        width: 0%;
        height: .5px;
        transition: all 500ms ease;
    }
    .card span:after {
        right: 0;
        bottom: 0;
        width: 0%;
        height: .5px;
        transition: all 500ms ease;
    }
    .card span:hover:before {
        width: 100%;
    }
    .card span:hover:after {
        width: 100%;
    }

}
@media (min-width: 600px) {
    div{
        padding: 5px;
        margin: 5px;
    }
    .titre{
        color: bisque;
        background-color: #6e9466;
        width: 50%;
        margin: auto;
        margin-top: 10px;
    }

    .containerProjets {
        display: flex;
        text-align: center;
        padding: 10px;

        margin: auto;



    }
    .fontImage{
        background-image: url("../../public/font_principal.png");
        background-repeat: repeat-y;
        font-family: 'Calligraffitti', cursive;
    }
    .card {
        border-radius: 30px;
        font-family: 'Calligraffitti', cursive;
       width: 100%
    }
    .image {
        background-image: url(../../public/img.png); /*or 70%, or what you want*/
        background-position: center;
        background-repeat: no-repeat;
        height: 1400px;
        width: 100%;
    }
    .card {
        background: rgb(160, 243, 153);
        background: radial-gradient(circle, rgb(123, 156, 58) 0%, rgb(254, 246, 185) 100%);
        line-height: 42px;
        padding: 0;
    }
    .card span {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
    }
    .card:before,
    .card:after {
        position: absolute;
        content: "";
        height: 0%;
        width: 1px;
        box-shadow:
                -1px -1px 20px 0px rgba(255,255,255,1),
                -4px -4px 5px 0px rgba(255,255,255,1),
                7px 7px 20px 0px rgba(0,0,0,.4),
                4px 4px 5px 0px rgba(0,0,0,.3);
    }
    .card:before {
        right: 0;
        top: 0;
        transition: all 500ms ease;
    }
    .card:after {
        left: 0;
        bottom: 0;
        transition: all 500ms ease;
    }

    .card:hover:before {
        transition: all 500ms ease;
        height: 100%;
    }
    .card:hover:after {
        transition: all 500ms ease;
        height: 100%;
    }
    .card span:before,
    .card span:after {
        position: absolute;
        content: "";
        box-shadow:
                -1px -1px 20px 0px rgba(255,255,255,1),
                -4px -4px 5px 0px rgba(255,255,255,1),
                7px 7px 20px 0px rgba(0,0,0,.4),
                4px 4px 5px 0px rgba(0,0,0,.3);
    }
    .card span:before {
        left: 0;
        top: 0;
        width: 0%;
        height: .5px;
        transition: all 500ms ease;
    }
    .card span:after {
        right: 0;
        bottom: 0;
        width: 0%;
        height: .5px;
        transition: all 500ms ease;
    }
    .card span:hover:before {
        width: 100%;
    }
    .card span:hover:after {
        width: 100%;
    }
    .dispositionImageBoulot{
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }

}
h2{color: green}