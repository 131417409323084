*{
    box-sizing: border-box;
}
body{
    margin: 0;
    padding: 10px;

}
#root{
    position: relative;
}

.section{
position: relative;
    text-align: center;
    font-size: 20px;
}
h2{
 text-align: center;
}

h1,h2,h3,h4,h5{
    margin: 0;

}

h1,p,h2,h5,span{
    color: #67d579;

    z-index: 1;
}
h2{
    color: #ceba6b;
}
p{text-align: center}
.aboutTechno{
    width: 50vw;
}

