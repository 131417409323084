@import url('https://fonts.googleapis.com/css2?family=Calligraffitti&display=swap');

.qui{
    color: #9fcb70;
}
.container{
    margin: 5px;
    padding: 5px;
    flex: 1;
    background-color: #31332e;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    font-family: 'Calligraffitti', cursive;
    align-items: center;
    margin-bottom: 40px;
}
.container div{
    flex: auto;
    font-size: 25px;
    align-items: center;
    justify-content: center;
    text-align: center;
}
a{
    color: darkseagreen;
    font-size: large;
    font-weight: bold;
}