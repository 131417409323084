
@media (max-width: 599px) {
    .card {

        border: black 3px solid;
        padding: 10px;
        margin-left: 20px;
        margin-right: 20px;
        flex-direction: column;
        border-radius: 30px;
        text-align: center;
    }
}

@media (min-width: 600px) {
    @import url('https://fonts.googleapis.com/css2?family=Calligraffitti&display=swap');

    .card {
        padding: 10px;


        border-radius: 30px;
        text-align: center;
        font-family: 'Calligraffitti', cursive;
    }
}

